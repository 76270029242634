import React, { useEffect, useState } from "react";
import "./App.css";
import ClaimtechSDK from "claimtech_roadside_sdk_test1";
import {
  useNavigate,
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import axios from "axios";

function App() {
  const navigate = useNavigate();
  const [token, setToken] = useState();

  const queryParams = new URLSearchParams(window.location.search);

  const googleAPIKey = queryParams.get("googleAPIKey");
  const IdNumber = queryParams.get("IdNumber");
  // const emailId = queryParams.get("emailId");
  const firstName = queryParams.get("firstName");
  const lastName = queryParams.get("lastName");
  const service = queryParams.get("service");
  const playerId = queryParams.get("playerId");

  useEffect(() => {
    const signupAndLogin = async () => {
      try {
        const IdNumber = queryParams.get("IdNumber");
        // const email = queryParams.get("emailId");
        const firstName = queryParams.get("firstName");
        const username = `${firstName}123`;
        const lastName = queryParams.get("lastName");
        const service = queryParams.get("service");

        const role = "ROLE_CLIENT";
        /*  const password = `${firstName.substring(0, 4)}${Math.random()
          .toString(36)
          .substring(2, 5)}`; */
        const password = `${firstName}${lastName}@123`;

        const details = {
          firstName: firstName,
          email: IdNumber,
          lastName: lastName,
          username: IdNumber,
          password: password,
          role: role,
        };

        // First, attempt to sign in
        try {
          const loginResponse = await axios.post(
            "https://crashassistprod.claimtec.co.za/api/auth/signin",
            {
              username: IdNumber,
              password,
            }
          );

          if (loginResponse.status === 200) {
            // Sign in successful
            // console.log("Login successful:", loginResponse.data);
            setToken(loginResponse.data.token);
          }
        } catch (loginError) {
          if (loginError.response && loginError.response.status === 401) {
            // Sign in failed, possibly because the user doesn't exist
            // console.log("User not found, attempting to sign up...");

            // Signup API call
            const signupResponse = await axios.post(
              "https://crashassistprod.claimtec.co.za/api/auth/signup",
              details
            );

            if (signupResponse.status === 201) {
              //console.log("Signup successful:", signupResponse.data);

              // After successful signup, attempt to sign in again
              const loginResponse = await axios.post(
                "https://crashassistprod.claimtec.co.za/api/auth/signin",
                {
                  username: IdNumber,
                  password,
                }
              );

              if (loginResponse.status === 200) {
                //console.log("Login successful:", loginResponse.data);
                setToken(loginResponse.data.token);
              } else {
                console.error("Login after signup failed:", loginResponse.data);
              }
            } else {
              console.error("Signup failed:", signupResponse.data);
            }
          } else {
            console.error("Error during login attempt:", loginError);
          }
        }
      } catch (error) {
        console.error("Error in signup or login process:", error);
      }
    };

    signupAndLogin();
  }, []);
 

  return (
    <div>
      <Routes>
        <Route
          path="/*"
          element={
            <ClaimtechSDK
              config={{
                googleAPIKey,
                service,
                IdNumber,
                /*     emailId, */
                firstName,
                lastName,
                token,
                playerId,
              }}
            />
          }
        />
      </Routes>
      {/*  <ClaimtechSDK
        config={{
          googleAPIKey,
          service,
          IdNumber,
          emailId,
          firstName,
          lastName,
          token,
        }}
      /> */}
    </div>
  );
}

export default App;
